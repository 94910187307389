$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";.sw-resource-library.card {
	background: map-get($backgrounds, sepia-bg);
    height: rem(230);
    position: relative;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;

    .card-body {
      flex: 1 1 auto;
      min-height: 1px;
      padding:2.5rem;
      text-align:center;
      color: map-get($colors, body);
    }

    .card-title {
      margin-bottom: .75rem;
      font-weight: map-get($font-weights, semibold);
      color: map-get($colors, body);
    }

    .card-text:last-child {
      margin-bottom: 0;
      color: map-get($colors, body);
    }

    .card-img-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 1.25rem;
      border-radius: calc(.25rem - 1px);
      color: map-get($colors, body);
    }

    .card-img-top {
      flex-shrink: 0;
      width: 100%;
      border-top-left-radius: calc(.25rem - 1px);
      border-top-right-radius: calc(.25rem - 1px);
      color: map-get($colors, body);
      height: rem(230);
    }

    &:hover .card-img-top {
      display: block;
    }
}
